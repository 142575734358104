import { React, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import "./App.css";
import Footer from "./Web-component/Footer";
import Header from "./Web-component/Header";
import HeaderSpaceCover from "./Web-component/HeaderSpaceCover";
import Home from "./Web-component/Home";
import Testimonial from "./Web-component/Testimonial";
import AboutUS from "./Web-component/AboutUs";
import FAQ from "./Web-component/FAQ";
import Blog from "./Web-component/Blog";
import Blogs from "./Web-component/Blogs";
import SearchJobResult from "./Web-component/SearchJobResult";
import AllLatestJob from "./Web-component/AllLatestJob";
import InterViewQuesResult from "./Web-component/InterViewQuesResult";
import TopEmployer from "./Web-component/TopEmployer";
// import AllRoleList from "./Web-component/AllRoleList";
import ScrollToTop from "./Web-component/ScrollToTop";
import SearchCandidateResult from "./Web-component/SearchCandidateResult";
// import GTM from "./Web-component/gtm";
import ContactUs from "./Web-component/ContactUs";
import Business from "./Web-component/Business";
import SearchEmployerResult from "./Web-component/SearchEmployerResult";

// Import GTM components
import GTMHeadScript from "./Web-component/GTMHeadScript";
import GTMBodyNoScript from "./Web-component/GTMBodyNoScript";
import GTMInitializer from "./Web-component/GTMInitializer";
import MetaPixel from "./Web-component/MetaPixel";
import { Helmet } from "react-helmet";


function App() {
  const gtmId = "GTM-T433G3P2"; // Replace with your GTM ID

  useEffect(() => {
    // This effect is to ensure that the canonical tag is set correctly when the component mounts
    const canonicalURL = window.location.origin + window.location.pathname;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalURL);
    }
  }, [window.location.pathname]);

  return (
    <ScrollToTop>
      <div className="App">
        {/* Inject GTM in head and body */}
        <GTMHeadScript gtmId={gtmId} />
        <GTMBodyNoScript gtmId={gtmId} />
        <GTMInitializer gtmId={gtmId} />
        <MetaPixel />
        <Helmet>
          <link
            rel="canonical"
            href={window.location.origin + window.location.pathname}
          />
        </Helmet>
        <Header />
        <HeaderSpaceCover />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogs/:postName?/:postId?" element={<Blogs />} />
          <Route
            path="/searchjobresult/:jobTitle?/:jobLocation?/:jobType?"
            element={<SearchJobResult />}
          />
          <Route
            path="/SearchCandidateResult/:sectorName?/:industryName?/:jobLocation?"
            element={<SearchCandidateResult />}
          />
          <Route
            path="/searchemployerresult/:employerName?/:jobLocation?"
            element={<SearchEmployerResult />}
          />
          <Route path="/allLatestJob" element={<AllLatestJob />} />
          <Route
            path="/interviewquesresult/:slug?/:count?"
            element={<InterViewQuesResult />}
          />
          <Route path="/topemployer" element={<TopEmployer />} />
          {/* <Route path="/allrolelist" element={<AllRoleList />} /> */}
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/business" element={<Business />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
        <Testimonial />
        <Footer />
      </div>
    </ScrollToTop>
  );
}

export default App;
