// import { a } from "@react-spring/web";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function SearchEmployerResult() {
  let urlParam = useParams();

  let [employerDetail, setEmployerDetail] = useState();
  let [errorMessage, setErrorMessage] = useState();
  let [pageImg, setPageImg] = useState();
  let [topEmployer, setTopEmployer] = useState([]);
  let [topempStatus, setTopEmpStatus] = useState(false);

  useEffect(() => {
    let fetchpage = async () => {
      let pageResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/imagesForWeb`
      );
      let ImgResult = await pageResponse.json();

      setPageImg(ImgResult.data.Search_Employer);
    };
    setTimeout(() => {
      fetchpage();
    }, 200);
  }, []);

  useEffect(() => {
    const topEmployerlist = async () => {
      let topEmpResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-TopEmployer`
      );
      let topEmpData = await topEmpResponse.json();
      setTopEmployer(topEmpData.data.employerDetail);
      let StatusValue = topEmpData.data.status;
      if (StatusValue === 200) {
        setTopEmpStatus(true);
      }
    };
    setTimeout(() => {
      topEmployerlist();
    }, 300);
  }, []);

  useEffect(() => {
    let fetchEmp = async () => {
      let EmpResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/employerSearch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            employerTitle: urlParam.employerName ? urlParam.employerName : "",
            employerLocation: urlParam.jobLocation ? urlParam.jobLocation : "",
          }),
        }
      );

      let result = await EmpResponse.json();
      // console.log("result", result);
      if (result.data.status === 404) {
        setErrorMessage(result.data.message);
      } else {
        const searchData = result.data || []; // Ensure searchData is always an array
        setEmployerDetail(Array.isArray(searchData) ? searchData : []);
        setErrorMessage("");
      }
    };
    setTimeout(() => {
      fetchEmp();
    }, 400);
  }, [urlParam]);

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  return (
    <>
      <section className="job-list-hero blog-hero">
        {/* <LazyLoad className="searchJob-banner blog-hero"> */}
        <img src={pageImg} alt="" loading="eager" />
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>Arbeitgebersuche</h1>
        </div>
      </section>

      <section className="new-jobs-section new-job-grey">
        <div className="container">
          <div className="new-jobs">
            <h2 className="top-employer-heading">Top Arbeitgeber</h2>
            <div className="items">
              {topempStatus ? (
                topEmployer.map((item, i) => (
                  <div
                    className="item-card"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay={`${i * 2}00`}
                    data-aos-offset="100"
                  >
                    <a
                      href={`${process.env.REACT_APP_HOST_URL}employer/${item.employer_slug}`}
                    >
                      <div className="jobs" key={i}>
                        <div className="image-wrapper">
                          <img
                            src={
                              item.profileIMG
                                ? item.profileIMG
                                : `${process.env.REACT_APP_HOST_URL}wp-content/uploads/2024/07/JobwishLogo.png`
                            }
                            alt=""
                          />
                        </div>

                        <div className="job-text">
                          <h3>{item.employer_Name}</h3>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.employer_bio
                                .split(" ")
                                .slice(0, 50)
                                .join(" "),
                            }}
                          />
                          <p>
                            <strong>Kontakt:</strong> {item.employer_contact}
                          </p>
                          <p>
                            <strong>Email:</strong> {item.employer_email}{" "}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              ) : (
                <div className="topempstatusmsg">
                  {topEmployer}
                  <div className="error">
                    <Link to={`/`}>{<span>Geh zurück</span>}</Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {errorMessage ? (
        <div className="error">
          {errorMessage} <Link to={`/`}>{<span>Geh zurück</span>}</Link>
        </div>
      ) : (
        <section className="searched-employer-section">
          <div className="container">
            <div className="searched-employer-list">
              {employerDetail
                ? employerDetail.map((item, i) => (
                    <div className="single-employer-wrap" key={i}>
                      <a
                        href={`${process.env.REACT_APP_HOST_URL}employer/${item.emp_slug}`}
                      >
                        <div className="single-employer-inner-wrap">
                          <div className="image-wrapper">
                            <img src={item.emp_Profile_Img} alt="" />
                          </div>

                          <div className="employer-details-wrap">
                            <div className="name-text-wrap">
                              <p>{item.emp_Name}</p>
                            </div>
                            <div className="other-details-wrap">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.emp_Bio
                                    .split(" ")
                                    .slice(0, 50)
                                    .join(" "),
                                }}
                              />
                              <p>
                                <strong>Contact:</strong> {item.emp_Phone}
                              </p>
                              <p>
                                <strong>Email:</strong> {item.emp_Email}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default SearchEmployerResult;
